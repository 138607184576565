import React from "react";
import ClassFinderQuestion from "./ClassFinderQuestion";

export default function ClassFinder({
  classList,
  setSelectedClasses,
  currentQuestion,
  goToQuestion,
  onCompleted,
  setProgress
}) {
  const QUESTIONS = [
    {
      number: 1,
      question: "Do you like sweaty cardio?",
      answers: [
        {
          label: "Yes",
          onSelect: () => {
            setSelectedClasses([
              classList.trifectaCombo,
              classList.rhythmRunRide
            ]);
            goToQuestion(currentQuestion + 1);
            setProgress(20);
          }
        },
        {
          label: "No",
          onSelect: () => {
            goToQuestion(currentQuestion + 1);
            setProgress(40);
          }
        }
      ]
    },
    {
      number: 2,
      question: "What is your fitness focus?",
      answers: [
        {
          label: "Strength",
          onSelect: () => {
            goToQuestion(currentQuestion + 1);
            setProgress(60);
          }
        },
        {
          label: "Flexibility",
          onSelect: () => {
            goToQuestion(currentQuestion + 2);
            setProgress(80);
          }
        },
        {
          label: "Both",
          onSelect: () => {
            goToQuestion(currentQuestion + 1);
            setProgress(60);
          }
        }
      ]
    },
    {
      number: 3,
      question: "Are you new to Pilates?",
      answers: [
        {
          label: "Yes",
          onSelect: () => {
            setSelectedClasses([classList.yogalates, classList.pilatesFusion]);
            goToQuestion(currentQuestion + 1);
            setProgress(80);
          }
        },
        {
          label: "No",
          onSelect: () => {
            setSelectedClasses([classList.yogalates, classList.pilatesFusion]);
            goToQuestion(currentQuestion + 1);
            setProgress(80);
          }
        }
      ]
    },
    {
      number: 4,
      question: "Are you new to Yoga?",
      answers: [
        {
          label: "Yes",
          onSelect: () => {
            setSelectedClasses([
              classList.yogalates,
              classList.yogaZen,
              classList.yogaAlign
            ]);
            onCompleted();
            setProgress(100);
          }
        },
        {
          label: "No",
          onSelect: () => {
            setSelectedClasses([
              classList.yogalates,
              classList.yogaZen,
              classList.yogaFlow
            ]);
            onCompleted();
            setProgress(100);
          }
        }
      ]
    }
  ];
  return (
    <div>
      {QUESTIONS.filter(question => question.number === currentQuestion).map(
        question => (
          <ClassFinderQuestion key={question.number} question={question} />
        )
      )}
    </div>
  );
}
