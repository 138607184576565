import React from "react";
import { Box, Button } from "@material-ui/core";

export default function ClassFinderQuestion({
  question: { question, answers }
}) {
  return (
    <div>
      <p>{question}</p>
      <Box display="flex">
        {answers.map(({ label, onSelect }) => (
          <Box key={label} mr={3}>
            <Button onClick={onSelect} variant="contained">
              {label}
            </Button>
          </Box>
        ))}
      </Box>
    </div>
  );
}
