import React from "react";

export default function ClassList({ classes }) {
  const classListItems = [];
  classes.forEach(({ label }) =>
    classListItems.push(<li key={label}>{label}</li>)
  );
  return (
    <div>
      <h3>Your classes</h3>
      <ul>{classListItems}</ul>
    </div>
  );
}
