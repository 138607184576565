export default {
  trifecta: {
    label: "TRIfecta"
  },
  trifectaCombo: {
    label: "TRIfecta Combo"
  },
  rhythmRunRide: {
    label: "RHYTHM Run/Ride"
  },
  cycleStrong: {
    label: "Cycle STRONG"
  },
  cyclePilates: {
    label: "Cycle / Pilates"
  },
  cycleYoga: {
    label: "Cycle / Yoga"
  },
  pilatesAlign: {
    label: "Pilates ALIGN"
  },
  pilatesFusion: {
    label: "Pilates FUSION"
  },
  pilatesStrong: {
    label: "Pilates STRONG"
  },
  yogalates: {
    label: "Yogalates"
  },
  yogaZen: {
    label: "Yoga ZEN"
  },
  yogaAlign: {
    label: "Yoga ALIGN"
  },
  yogaFlow: {
    label: "Yoga Flow"
  }
};
