import React, { Fragment, useState } from "react";
import { Box, Button, Chip, LinearProgress } from "@material-ui/core";
import ClassFinder from "./ClassFinder.js";
import ClassList from "./ClassList.js";
import EmailSignup from "./EmailSignup.js";
import classData from "./data/classes.js";
import "./styles.css";

export default function App() {
  const defaultClasses = new Set([classData.trifecta]);
  const [progress, setProgress] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [classes, setClasses] = useState(defaultClasses);
  const [question, setQuestion] = useState(1);
  const onAddClasses = classesToAdd => {
    classesToAdd.map(classToAdd => classes.add(classToAdd));
    setClasses(classes);
  };
  const onQuizCompleted = () => setQuizCompleted(true);
  const onRestartButtonClick = () => {
    setProgress(0);
    setQuizCompleted(false);
    setClasses(defaultClasses);
    setQuestion(1);
  };
  return (
    <div className="App">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button onClick={onRestartButtonClick}>Restart the quiz</Button>
        <Chip label={`${progress}%`} variant="outlined" size="small" />
      </Box>
      <LinearProgress variant="determinate" value={progress} />
      {!quizCompleted && (
        <ClassFinder
          classList={classData}
          setSelectedClasses={onAddClasses}
          currentQuestion={question}
          goToQuestion={setQuestion}
          onCompleted={onQuizCompleted}
          progress={progress}
          setProgress={setProgress}
        />
      )}
      {quizCompleted && (
        <Fragment>
          <ClassList classes={classes} />
          <EmailSignup classes={classes} />
        </Fragment>
      )}
    </div>
  );
}
