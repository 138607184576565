import React from "react";

function embeddedFormHTML(classes) {
  const classInputs = [];
  classes.forEach(({ label }, i) =>
    classInputs.push(
      `<input type="text" value="${label}" name="CF_${i + 1}" id="mce-CF_${i +
        1}">`
    )
  );
  return `
    <!-- Begin Mailchimp Signup Form -->
    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
    <style type="text/css">
      #mc_embed_signup{background:#fff; clear:left;}
      #mc_embed_signup form{padding:0;}
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
        We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup">
    <form action="https://petragulicher.us19.list-manage.com/subscribe/post?u=9a6c967754183e2ab71f603ea&amp;id=a28a4d9d9f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
      <div id="mc_embed_signup_scroll">
        <div class="mc-field-group">
          <label for="mce-EMAIL">Email Address </label>
          <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
        </div>
        <div id="mce-responses" class="clear">
          <div class="response" id="mce-error-response" style="display:none"></div>
          <div class="response" id="mce-success-response" style="display:none"></div>
        </div>
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true">
          <input type="text" name="b_9a6c967754183e2ab71f603ea_a28a4d9d9f" tabindex="-1" value="">
          ${classInputs.join("")}
        </div>
        <div class="clear">
          <input type="submit" value="Email me my classes" name="subscribe" id="mc-embedded-subscribe" class="button">
        </div>
      </div>
    </form>
    </div>
    <!--End mc_embed_signup-->
  `;
}

export default function EmailSignup({ classes }) {
  return (
    <div>
      <h3>We'll email your classes to you</h3>
      <div dangerouslySetInnerHTML={{ __html: embeddedFormHTML(classes) }} />
    </div>
  );
}
